import request from '@/utils/request';

// 获取个人信息
export function GetInfo() {
  return request({
    url: '/wisc/youth/baseInfo/getLoginInfo',
    method: 'GET',
  });
}

// 获取记分编号
export function FindScoringNum() {
  return request({
    url: '/wisc/youth/report/findScoringNum',
    method: 'GET',
  });
}
// 获取实足年龄
export function GetRealAge(params) {
  return request({
    url: '/wisc/youth/report/getRealAge',
    method: 'GET',
    params:params
  });
}

