<template>
  <div class="main-container">
    <div class="main-header">
      <div class="logo-box">
        <img src="/images/logo.png" alt="">
      </div>
      <div class="name-box">韦氏儿童智力量表第四版中文版计分系统</div>
      <topMenu :num="2"></topMenu>
    </div>
    <div class="main-body column">
      <div class="header">
        <div class="title-box"><img src="/images/input-header-icon.png" alt=""><span class="name">基本信息</span></div>
      </div>
      <div class="body">
        <div class="de" style="margin:0 0 40px 0;font-size: 14px">ps: <span style="color: #832C75">*</span>为必填项</div>
        <a-form-model ref="form" :model="formModel"  :rules="formRules" autoComplete="off" :label-col="labelCol" :wrapper-col="wrapperCol" labelAlign="left">
          <a-row>
            <a-col :span="12">
              <a-row>
                <a-col :span="24">
                  <a-form-model-item label="诊疗卡号" prop="archives_num">
                    <div class="flex">
                      <a-input allowClear class="cu-input1" v-model="formModel.archives_num"/>
                    </div>
                  </a-form-model-item>
                  <a-form-model-item label="记分编号" prop="scoring_num">
                    <div class="flex">
                      <a-input class="cu-input1" v-model="formModel.scoring_num" disabled/>
                    </div>
                  </a-form-model-item>
                  <a-form-model-item label="儿童姓名" prop="name">
                    <div class="flex">
                      <a-input allowClear class="cu-input1" v-model="formModel.name"/>
                    </div>
                  </a-form-model-item>
                  <a-form-model-item label="主试姓名" prop="examiner_name">
                    <div class="flex">
                      <a-input class="cu-input1" v-model="formModel.examiner_name" disabled/>
                    </div>
                  </a-form-model-item>
                  <a-form-model-item label="儿童性别" prop="gender">
                    <div class="flex" style="margin-top: 10px">
                      <a-radio-group  v-model="formModel.gender">
                        <a-radio :value="1">男</a-radio>
                        <a-radio :value="2">女</a-radio>
                      </a-radio-group>
                    </div>
                  </a-form-model-item>
                  <a-form-model-item label="所在年级" prop="grade">
                    <a-select placeholder="请选择" v-model="formModel.grade">
                      <a-select-option value="幼儿园大班">幼儿园大班</a-select-option>
                      <a-select-option value="学前班">学前班</a-select-option>
                      <a-select-option value="小学一年级">小学一年级</a-select-option>
                      <a-select-option value="小学二年级">小学二年级</a-select-option>
                      <a-select-option value="小学三年级">小学三年级</a-select-option>
                      <a-select-option value="小学四年级">小学四年级</a-select-option>
                      <a-select-option value="小学五年级">小学五年级</a-select-option>
                      <a-select-option value="小学六年级">小学六年级</a-select-option>
                      <a-select-option value="初中一年级">初中一年级</a-select-option>
                      <a-select-option value="初中二年级">初中二年级</a-select-option>
                      <a-select-option value="初中三年级">初中三年级</a-select-option>
                      <a-select-option value="高中一年级">高中一年级</a-select-option>
                      <a-select-option value="高中二年级">高中二年级</a-select-option>
                      <a-select-option value="高中三年级">高中三年级</a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
              </a-row>
            </a-col>
            <a-col :span="11" class="right-box">
              <a-row>
                <a-col :span="24">
                  <a-form-model-item label="少数民族" prop="is_minorities">
                    <div class="flex" style="margin-top: 10px">
                      <a-radio-group default-value="否" v-model="formModel.is_minorities">
                        <a-radio :value="2">是</a-radio>
                        <a-radio :value="1">否</a-radio>
                      </a-radio-group>
                    </div>
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="24">
                  <a-form-model-item label="用手习惯" prop="hand_habit">
                    <a-select allowClear default-value="2" style="width: 260px;height: 42px !important;line-height: 42px !important;;" dropdownClassName="cu-select" v-model="formModel.hand_habit">
                      <a-select-option :value="1">左手</a-select-option>
                      <a-select-option :value="2">右手</a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="24">
                  <a-form-model-item label="出生日期" prop="born_date">
                    <a-date-picker :disabled-date="disabledDate" @change="changeBornTime" allowClear  format="YYYY-MM-DD" style="width: 100%" class="cu-input1" v-model="formModel.born_date"/>
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="24">
                  <a-form-model-item label="家长文化" prop="parent_culture">
                    <a-select placeholder="请选择" v-model="formModel.parent_culture">
                      <a-select-option value="文盲">文盲</a-select-option>
                      <a-select-option value="小学毕业">小学毕业</a-select-option>
                      <a-select-option value="初中毕业">初中毕业</a-select-option>
                      <a-select-option value="高中毕业">高中毕业</a-select-option>
                      <a-select-option value="本科毕业">本科毕业</a-select-option>
                      <a-select-option value="专科毕业">专科毕业</a-select-option>
                      <a-select-option value="硕士毕业">硕士毕业</a-select-option>
                      <a-select-option value="博士毕业">博士毕业</a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="24">
<!--                  <a-form-model-item label="实际年龄" prop="real_age">-->
<!--                    <a-input class="cu-input1" disabled placeholder="输完出生日期后将自动计算" v-model="formModel.real_age"/>-->
<!--                  </a-form-model-item>-->
                </a-col>
              </a-row>
            </a-col>
          </a-row>
          <div class="line1" style="margin-bottom: 30px;margin-top: 20px"></div>
          <a-row>
            <a-col :span="12">
              <a-form-model-item label="备注说明">
                <div class="flex">
                  <a-input allowClear class="cu-input1" placeholder="" v-model="formModel.remark"/>
                </div>
              </a-form-model-item>
            </a-col>
          </a-row>
          <div class="line1" style="margin-bottom: 40px;margin-top: 10px"></div>
          <div style="display: flex;align-items: center;justify-content: center;cursor: pointer">
            <div class="btn" @click="actionData">提交</div>
          </div>
        </a-form-model>
      </div>
    </div>
    <footer-bottom></footer-bottom>
  </div>
</template>

<script>
import * as Api from './api';
import * as commonApi from '@/commonApi';
import {message} from "ant-design-vue";
import {FindScoringNum, GetRealAge} from "./api";
import moment from "moment";
export default {
  data() {
    return {
      labelCol: {span: 6, style: {color: '#7E8589', fontWeight: '500'}},
      wrapperCol: {span: 16},
      formModel: {
        archives_num: '',
        scoring_num: '',
        name: '',
        examiner_name: '',
        gender: 1,
        is_minorities: 1,
        hand_habit: 2,
        exam_address: '',
        born_date: '',
        real_age: '',
        remark: '',
        certificate_no: '',
        grade: undefined,
        parent_culture: undefined
      },
      formRules: {
        // archives_num: [
        //   { required: true, message: '请输入诊疗卡号', trigger: 'change' },
        // ],
        scoring_num: [
          { required: true, message: '请生成记分编号', trigger: 'change' },
        ],
        name: [
          { required: true, message: '请输入儿童姓名', trigger: 'change' },
        ],
        examiner_name: [
          { required: true, message: '请获取主试姓名', trigger: 'change' },
        ],
        // gender: [
        //   { required: true, message: '请选择儿童性别', trigger: 'change' },
        // ],
        // grade: [
        //   { required: true, message: '请选择所在年级', trigger: 'change' },
        // ],
        // is_minorities: [
        //   { required: true, message: '请选择是否少数民族', trigger: 'change' },
        // ],
        // hand_habit: [
        //   { required: true, message: '请选择用手习惯', trigger: 'change' },
        // ],
        born_date: [
          { required: true, message: '请选择出生日期', trigger: 'change' },
        ],
        exam_address: [
          { required: true, message: '请输入测试地点', trigger: 'change' },
        ],
        real_age: [
          { required: true, message: '请选择出生日期后生成实际年龄', trigger: 'change' },
        ],
        // parent_culture: [
        //   { required: true, message: '请选择家长文化', trigger: 'change' },
        // ]
      },
    };
  },
  mounted() {
    this.getInfo();
    this.findScoringNum();
  },
  methods: {
    disabledDate(current) {
      return  moment(current).format('L') > moment(new Date()).format('L')
    },
    async actionData() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          window.localStorage.setItem('base_info', JSON.stringify(this.formModel));
          this.$router.push({path: '/select-age'})
        }
      });
    },
    goSelectAge() {
      this.$router.push({path: '/select-age'})
    },
    async getInfo() {
      const res = await Api.GetInfo({});
      if (res && res.code == 0) {
        this.formModel.examiner_name = res.chiefExaminer.name;
        this.formModel.certificate_no = res.chiefExaminer.certificate_no;
      }
    },
    async findScoringNum() {
      const res = await Api.FindScoringNum({});
      if (res && res.code == 0) {
        this.formModel.scoring_num = this.formModel.certificate_no + res.scoringNum;
      }
    },
    changeBornTime(e,s) {
      this.formModel.born_date = s;
    }

  }
};
</script>
<style>

</style>
<style scoped>

.main-body {
  background-image: url("/images/maintain-bg.png");
  align-items: flex-start;
  justify-content: flex-start;
}

.line1 {
  border: 1px solid #f8f8f8;
  margin-bottom: 20px;
}

.ant-form-item-control .ant-form-item-children {
  display: flex;
}

.num-de {
  font-size: 12px;
  font-weight: 400;
  color: #999A9A;
  width: 100px;
  text-align: center;
}

.flex {
  display: flex;
}
.right-box {
  height: 100%;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #eee;
  padding-left: 70px;
  justify-content: flex-start;
}
.right-title {
  font-size: 14px;
  font-weight: 600;
  color: #832C75;
  margin-bottom: 20px;
}
.cu-select{
  line-height: 42px;
  height: 42px;
}
.btn {
  background: #832C75;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 45px;
  text-align: center;
  cursor: pointer;
  padding: 0 40px;
}
.btn.sh {
  border: 1px solid #832B75;
  border-radius: 5px;
  font-weight: 400;
  color: #832B75;
  line-height: 45px;
  background: #FFFFFF;
  display: inline;
  display: flex;
  align-items: center;
}
.ant-form-item {
  margin-bottom: 12px !important;
}
</style>
